import React, { useState } from "react"
import Layout from "../components/layout"

const PlayContact = () => {
  const product = "Big Brain Energy"
  const app_type = "web"
  const submitUrl = "https://submit-form.com/MKYKSK9sQ"

  const [email, setEmail] = useState("")
  const [feedback, setFeedback] = useState("")

  const handleSubmit = async e => {
    e.preventDefault()
    const formData = {
      product,
      app_type,
      email,
      feedback,
    }

    try {
      const response = await fetch(submitUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(formData),
      })

      if (response.ok) {
        alert("Feedback submitted successfully")
        setEmail("")
        setFeedback("")
      } else {
        alert("Error submitting feedback")
      }
    } catch (error) {
      alert("Error submitting feedback")
    }
  }

  return (
    <Layout>
      <div>
        <div>
          <div className="max-w-lg mx-auto p-6 mt-2 bg-white shadow-md rounded-md">
            <div className="flex flex-col gap-[12px] mb-6">
              <h1 className="text-[#141414] text-[24px] font-semibold tracking-[-0.8px]">
                Thanks for playing Big Brain Energy!
              </h1>
              <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
                We hope you enjoy the app. We're just getting started and would
                love to hear what you think!
              </p>
              <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
                Leave some feedback below. If you'd like us to get back to you
                (for example, for support questions) please leave your email as
                well.
              </p>
              <p className="text-[#141414] text-[18px] font-medium leading-[150%]">
                Speak soon. BB Energy Team 👋
              </p>
              <a href="https://bbenergy.app/play">
                <p className="text-text-link text-[18px] font-medium leading-[150%]">
                  Go to main site
                </p>
              </a>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Email:
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  placeholder="Your email (optional)"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="feedback"
                  className="block text-gray-700 font-medium mb-2"
                >
                  Feedback:
                </label>
                <textarea
                  id="feedback"
                  name="feedback"
                  value={feedback}
                  onChange={e => setFeedback(e.target.value)}
                  placeholder="Your feedback (optional)"
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-black text-white p-2 rounded-md hover:bg-blue-600"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PlayContact
